






























































import {Vue, Component} from 'vue-property-decorator';
import CompanyDTO from "@/dto/company/CompanyDTO";
import OtherCompanyBankProductDTO from "@/dto/company/OtherCompanyBankProductDTO";
import {namespace} from "vuex-class";
import CompanyService from "@/services/CompanyService";
import Workspaces from "@/state/Workspaces";
import PortalInput from "@/components/common/PortalInput.vue";
import {BankProductType} from "@/dto/company/OtherCompanyBankProductDTO";
import {STATE_LIST} from "@/constants/ProfileConstants";
import {ResidencyStatus} from "@/constants/PersonConstants";
import AutoCompleteDTO from "@/components/common/AutoCompleteDTO";

const AppModule = namespace("App");

@Component({
  computed: {

    BankProductType() {
      return BankProductType
    }

  },
  components: {PortalInput}
})
export default class OtherCompanyBankProducts extends Vue {

  @AppModule.Action
  private startLoading!: () => void

  @AppModule.Action
  private stopLoading!: () => void;

  private message = "";
  private successful = true;

  private company: CompanyDTO | null = null;

  private accounts: Array<OtherCompanyBankProductDTO> = [];

  private newAccountPayload = new OtherCompanyBankProductDTO();

  bankProductType: BankProductType | null = null;

  mounted() {
    this.initPayload();
    this.loadCompany().then(
        () => this.loadAccounts()
    );
  }

  initPayload() {
    this.newAccountPayload = new OtherCompanyBankProductDTO();
    this.newAccountPayload.companyId = this.getId;
  }

  loadCompany() {
    this.startLoading();
    this.message = "";
    return CompanyService.getById(this.getId).then(
        ok => {
          this.company = ok.data;
          this.stopLoading();
        },
        error => {
          this.message = "Server error";
          this.successful = false;
          this.stopLoading();
        }
    )
  }

  loadAccounts() {
    this.startLoading();
    this.message = "";
    return CompanyService.getOtherBankAccounts(this.getId).then(
        ok => {
          this.accounts = ok.data;
          this.stopLoading();
        },
        error => {
          this.message = "Server error";
          this.successful = false;
          this.stopLoading();
        }
    )
  }

  saveAccount(account: OtherCompanyBankProductDTO) {
    account.companyId = this.getId;
    this.startLoading();
    this.message = "";
    return CompanyService.saveOtherBankAccount(account).then(
        ok => {
          this.stopLoading();
          this.initPayload();
          this.loadAccounts();
        },
        error => {
          this.message = "Server error";
          this.successful = false;
          this.stopLoading();
        }
    )
  }


  get getId() {
    return Workspaces.getCurrent.id;
  }

}
